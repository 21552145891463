<template>

    <div id="about">
      <vue-headful title="SpotDesign | About"/>
      <div class="col" v-if="data">
        <div class="text">{{data.summary}}</div>
        <div class="logs" v-for="(img,index) in data.logos">
            <img :src="img.file"/>
        </div>
      </div>

    </div>

</template>



<script>
import server from '@/server'
export default {
  data () {
    return {
      data: null
    }
  },
  methods:{

    catch: function(data){
      this.data = data;
      this.$emit('dologo');
    //  console.log(data);
    }

  },
  mounted(){
    server.fetch('about',this.catch)
  }
}
</script>
<style scoped lang="scss">
.col{text-align: center;}
.text{margin: 250px 0 30px 0; padding: 0 6%; font-size: 18px; color: #CCC; font-family: $font; text-align: left; }
.logs{width: 30%; margin: 1%; position: relative; padding: 30% 0 0 0; display: inline-block;
  @include sm {
    width: 20%; margin: 1%; padding: 20% 0 0 0;
  }
}
img{width: 100%; height: auto; position: absolute; top:50% ; left:50%; transform: translate(-50%,-50%); opacity: 0.5}
</style>
