<template>
  <div class="slide gpu" >
  <video :id="'vid'+index" ref="vid" playsinline muted>
    <source :src="file" type="video/mp4">
  </video>
</div>
</template>

<script>

// TAU = 360 deg
export default {

  data () {
    return {
      done: false,
      paused: false
    }
  },
  props:['file', 'index', 'slide', 'menu'],
  mounted(){
    this.checkVid();
    this.$refs.vid.ontimeupdate = this.update;
    this.$refs.vid.onCanplaythrough = this.sendReady;

  },
  watch: {
    menu: function (newVal, oldVal) {
      console.log('change');
      this.pause(newVal);
    }
  },
  methods: {
    reset: function(){
      this.done = false;
    },
    pause: function(state = true){
      this.paused = state;
      if(this.paused){
        this.$refs.vid.pause();
      } else {
        this.$refs.vid.play();
      }
    },
    update: function(){
      if(this.$refs.vid && !this.paused){
        let rem = this.$refs.vid.duration - this.$refs.vid.currentTime;
        if(rem < 1 && !this.done){
          this.done = true;
          setTimeout(this.reset,2000);
          this.$emit('done');
        }
      }
    },
    sendReady(){
      this.$emit('loaded',this.index);
    },

    checkVid(){
      if(!this.paused){
        if(this.$refs.vid.readyState >= 3){
          this.sendReady();
        } else {
          setTimeout(this.checkVid,300);
        }
      }
    }
  }
}
</script>
<style scoped lang="scss">
video{height: 70%; width: auto; position: absolute; top: 15%; left:50%; transform: translateX(-50%);
  @include sm {
     height: 100%;
     top:0;
  }

}

</style>
