<template>


<div id="carousel">
  <vue-headful title="SpotDesign | Games, app and web design" description="We create bespoke digital experiences. From 3D games for the large or small screen, web apps, simple branding, or UX and web design. With 20 years experience, SpotD has designed creative applications to enrich public spaces,  and online brand experiences for many well known attractions and brands."/>
  <component v-for="(item,index) in slides" class="slide cslide hide" :menu="menu" :id="'slide'+index" :key="'slide-'+index" :file="item.file" :point="item.point" :style="{'z-index':20+index}"
  :index="index"  @loaded="loaded" :ref="'slide'+index" @done="nextSlide"
  :is="item.type.charAt(0).toUpperCase() + item.type.slice(1) + 'Slide'">
  </component>
</div>

</template>


<script>
import ImageSlide from '@/components/elements/ImageSlide'
import VideoSlide from '@/components/elements/VideoSlide'
import server from '@/server'

export default {
  data () {
    return {
      slideNum: 0,
      lastPos:1,
      newNum:1,
      ready: false,
      slides: [],
      lod: []
    }
  },
  props: ['menu'],
  methods:{
    nextSlide(){

      this.lastPos = this.slideNum;

      if(this.slideNum < this.slides.length-1){
        this.newNum = this.slideNum+1;
      //  this.slideNum++;
      } else {
        this.newNum = 0;
      }

        this.doSlide();

    },
    hideSlide(lastPos){
      TweenLite.set('#slide'+lastPos,{className: '+=hide' });
    },
    doSlide(){
      this.slideNum = this.newNum;


      TweenLite.set('#slide'+this.slideNum,{className: '-=hide' });
      TweenLite.to('#slide'+this.slideNum,0.5,{opacity:1});
      TweenLite.to('#slide'+this.lastPos,0.5,{opacity:0, onComplete: this.hideSlide, onCompleteParams: [this.lastPos]});
      console.log(this.slides[this.slideNum].type);
      if(this.slides[this.slideNum].type == 'image'){
        setTimeout(this.nextSlide,5000);
      } else {
        let vid = document.getElementById("vid"+this.slideNum);
        if(vid){
          vid.play();
        }
      }

    },
    checkNum(){
      if(this.lod[this.newNum]){
        this.doSlide();
      } else {
        setTimeout(this.checkNum,500)
      }
    },
    catch: function(data){

      this.slides = data.data[0].slides;
    },
    loaded: function(pos){
    //  console.log(pos + ' loaded');
      this.lod[pos] = true;
      if(pos == 0){
        TweenLite.set('#slide'+this.slideNum,{className: '-=hide' });
        TweenLite.to('#slide'+this.slideNum,0.5,{opacity:1});
        setTimeout(this.nextSlide,5000);
      }
    //  console.log('loaded slide: ' + pos);
    }
  },
  mounted(){
    console.log('boom')
    server.fetch('home',this.catch)
  },
  components: {
    ImageSlide,
    VideoSlide
  }
}
</script>

<style scoped>
#preload{background: #000; z-index: 50}
#carousel{background: #CCC}
.slide{opacity: 0}
video{
  position: fixed;
 right: 0;
 bottom: 0;
 min-width: 100%;
 min-height: 100%;
}
</style>
