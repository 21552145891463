//import axios from 'axios';
const cachios = require('cachios');

    const executeQuery = (path, callback) => {

    cachios.get('/api/'+path).then((result) => {
          if (callback) {
              callback(result.data);
          }
          //console.log(result.data);
      }).catch((error) => {
          console.log(error);
      })


    };





export default {

  fetch: function(path, ret) {
  //  let dt = {};
console.log('FETCH');
    executeQuery(path, (data) => {

                    ret(data);
                });

   }
}
