<template>
<div class="slide gpu" :style="{'background-image': 'url(' + file + ')', 'background-position': (point[0].x*100) + '% ' + (point[0].y*100) + '%' }">

  <img ref="img" :src="file" id="loader" />

</div>
</template>

<script>

// TAU = 360 deg
export default {

  data () {
    return {

    }
  },
  props:['file','point','index', 'slide'],
  mounted(){
    this.checkLod();
  },
  methods: {
    checkLod(){
      if(this.$refs.img.complete){
        this.$emit('loaded',this.index);
      } else {
        setTimeout(this.checkLod,300);
      }
    }

  }
}
</script>
<style scoped lang="scss">
#loader{position: absolute; top: -3000px; left: -3000px}

.slide{
  background: no-repeat center center fixed;
  -webkit-background-size: auto 90%;
  -moz-background-size: auto 90%;
  -o-background-size: auto 90%;
  background-size: auto 90%;

}



</style>
