<template>

  <ZIllustration id="logo" width="300" height="300" :zoom="1">
    <ZAnchor :scale="scl"  :rotate="{ x: yrot}">
    <ZShape :stroke="dot" :color="dotCol" :path="[{ x: -110, y: -69.25, z:10 }]"/>
    <ZShape :stroke="stroke" :color="lineCol" :path="[{ x: -69.25, y: -69.25, z:0 }, { x:  0, y: -69.25, z:-10 }]"/>
    <ZShape :stroke="stroke" :color="lineCol" :path="[
      { x: 0, y: -69.25, z:-10 },
      { arc: [
        { x:  69.25, y: -69.25, z:-20 },
        { x:  69.25, y:  0 , z:-20},
      ]},

      { arc: [
        { x: 69.25, y:  69.25, z:-20 },
        { x:  0, y:  69.25, z:-30 },
      ]},
    ]"/>

    <ZShape :stroke="stroke" :color="lineCol" :path="[
      { x:  0, y: 69.25, z:-30 },
      { x:  -69.25, y: 69.25, z:-40 },
    ]"/>

    <ZShape :stroke="stroke" :color="lineCol" :path="[
      { x:  -69.25, y: 69.25, z:-40 },
      { x:  -69.25, y: -30, z:-50 },
    ]"/>

    <ZShape :stroke="stroke" :color="lineCol" :path="[
      { x:  -69.25, y: -30, z:-50 },
      { x:  0, y: -30, z:-60 },
    ]"/>

    <ZShape :stroke="stroke" :color="lineCol" :path="[
      { x: 0, y: -30, z:-60 },
      { arc: [
        { x:  26.75, y: -30, z:-64 },
        { x:  26.75, y:  0.75, z:-64 },
      ]},

      { arc: [
        { x: 26.75, y:  30, z:-73 },
        { x:  0, y:  30, z:-73 },
      ]},
    ]"/>

    <ZShape :stroke="stroke" :color="lineCol" :path="[
      { x: 0, y: 30,  z:-73 },
      { x:  -27, y: 30,  z:-76 },
    ]"/>

    <ZShape :stroke="stroke" :color="lineCol" :path=" [
      { x: -27, y: 30, z: -76 },
      { x:  -27, y: 0, z: -80 },
    ]"/>


    <ZShape :stroke="stroke" color="#6d6d6d" :path="[
      { x: -27, y: 0, z: -80 },
      { x:  -27, y: -28, z: -84 },
    ]"/>
</ZAnchor>

  </ZIllustration>
</template>

<script>
import { ZIllustration, ZShape, ZAnchor} from 'vue-zdog'
import {TAU} from 'zdog'
// TAU = 360 deg
export default {
  data () {
    return {
      yrot: 0
    }
  },
  props: ['lineCol','dotCol','stroke','dot', 'scl'],
  mounted(){
  },
  methods: {
    twang: function(){
    //  TweenLite.set(this, {yrot: TAU / 10})
      TweenLite.to(this, 1,{yrot: TAU / 4.2, ease: Sine.easeOut})
    }
  },
  components: {
    ZAnchor,
    ZIllustration,
    ZShape
  }
}
</script>
<style scoped>

</style>
