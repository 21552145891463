<template>

  <div id="work">
    <vue-headful :title="'SpotDesign | ' + this.entry.title"/>
    <close-icon id="icon" @clicked="goTo" :anim='true'/>

    <div class="col" v-if="this.entry.title">
      <h1>{{entry.title}}</h1>

      <iframe v-if="$route.params.slug == 'lebara-banners'" id="lebara" src="https://projects.spotdesign.co.uk/lebara/"></iframe>

    <div class="block" v-for="(img,index) in entry.media">
      
          <img v-if="img.type == 'image'" :src="img.file" :key="'image'+index" />

          <video v-if="img.type == 'video'" :id="'video'+index" ref="vid" playsinline autoplay controls>
            <source :src="img.file" type="video/mp4">
          </video>
     
    </div>
</div>

  </div>

</template>



<script>
import server from '@/server'
import CloseIcon from '@/components/elements/CloseIcon'
export default {
  data () {
    return {
      entry:{ title: "", media:[]},
      poster: '',
      lodNum: 0
    }
  },
  methods:{
    goTo(){
      console.log('back')
      this.$router.push({ name: 'work' });
    },
    catch: function(data){
      this.entry = data;
      for(var i=0; i<data.media.length; i++){
        if(data.media[i].feature){
          this.poster = data.media[i].file;
          return;
        }
      }

    //  console.log(data);
    },
    loaded(e){
      //TweenLite.to(e.currentTarget,0.5,{opacity:1, delay:0.1});
    }
  },
  components: {
    CloseIcon
  },
  mounted(){
  //  console.log(this.$route.params.slug);
    server.fetch('work/'+this.$route.params.slug,this.catch)
  }
}
</script>
<style scoped lang="scss">
.block{width: 96%; margin: 0 2%; display: inline-grid;
  @include lg {
    width: 46%;
    margin: 0 1%;
  }
}
#lebara{width: 728px; height: 90px; border:none; margin-bottom: 10px; display: none;
  @include md {
    display: inline-block;
  }
}
#work{padding:0; margin: 10px; text-align: center; background: none}
#icon {
  position: fixed; top:25px; right:25px; z-index: 1000;
  pointer-events: all;
  transform: scale(0.7,0.7);
  @include lg {
    top:40px; right:40px;
    transform: scale(1,1);
  }
}
.col img{width:100%; height: auto; margin-bottom: 10px; opacity: 1}
video{width:100%; margin-bottom: 10px;}
</style>
