var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ZIllustration',{attrs:{"id":"logo","width":"300","height":"300","zoom":1}},[_c('ZAnchor',{attrs:{"scale":_vm.scl,"rotate":{ x: _vm.yrot}}},[_c('ZShape',{attrs:{"stroke":_vm.dot,"color":_vm.dotCol,"path":[{ x: -110, y: -69.25, z:10 }]}}),_c('ZShape',{attrs:{"stroke":_vm.stroke,"color":_vm.lineCol,"path":[{ x: -69.25, y: -69.25, z:0 }, { x:  0, y: -69.25, z:-10 }]}}),_c('ZShape',{attrs:{"stroke":_vm.stroke,"color":_vm.lineCol,"path":[
      { x: 0, y: -69.25, z:-10 },
      { arc: [
        { x:  69.25, y: -69.25, z:-20 },
        { x:  69.25, y:  0 , z:-20} ]},

      { arc: [
        { x: 69.25, y:  69.25, z:-20 },
        { x:  0, y:  69.25, z:-30 } ]} ]}}),_c('ZShape',{attrs:{"stroke":_vm.stroke,"color":_vm.lineCol,"path":[
      { x:  0, y: 69.25, z:-30 },
      { x:  -69.25, y: 69.25, z:-40 } ]}}),_c('ZShape',{attrs:{"stroke":_vm.stroke,"color":_vm.lineCol,"path":[
      { x:  -69.25, y: 69.25, z:-40 },
      { x:  -69.25, y: -30, z:-50 } ]}}),_c('ZShape',{attrs:{"stroke":_vm.stroke,"color":_vm.lineCol,"path":[
      { x:  -69.25, y: -30, z:-50 },
      { x:  0, y: -30, z:-60 } ]}}),_c('ZShape',{attrs:{"stroke":_vm.stroke,"color":_vm.lineCol,"path":[
      { x: 0, y: -30, z:-60 },
      { arc: [
        { x:  26.75, y: -30, z:-64 },
        { x:  26.75, y:  0.75, z:-64 } ]},

      { arc: [
        { x: 26.75, y:  30, z:-73 },
        { x:  0, y:  30, z:-73 } ]} ]}}),_c('ZShape',{attrs:{"stroke":_vm.stroke,"color":_vm.lineCol,"path":[
      { x: 0, y: 30,  z:-73 },
      { x:  -27, y: 30,  z:-76 } ]}}),_c('ZShape',{attrs:{"stroke":_vm.stroke,"color":_vm.lineCol,"path":[
      { x: -27, y: 30, z: -76 },
      { x:  -27, y: 0, z: -80 } ]}}),_c('ZShape',{attrs:{"stroke":_vm.stroke,"color":"#6d6d6d","path":[
      { x: -27, y: 0, z: -80 },
      { x:  -27, y: -28, z: -84 } ]}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }