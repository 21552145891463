<template>
  <div class="gpu" @mousedown.stop="setForm">
  <ZIllustration :width="150" :height="150" :zoom="0.5">
<!-- plane! -->
    <ZAnchor :translate="{x: 0, z:40, y: -30}" :rotate="{z:pz/tau, x:px/tau, y:py}">
      <ZAnchor :rotate="{z:tau/40}">
        <ZShape :path="[ { x:0, y: -50 },{ x:  0, y:  50 },{ x: -50, y:  50 },{ x: 0, y:  -50 }]" :fill="true" color="#FFF" />
        <ZShape :path="[ { x:0, y: -50 },{ x:  0, y:  50 },{ x: 30, y:  50 },{ x: 0, y:  -50 }]" :rotate="{y:tau/6}" :fill="true" color="#CCC"  />
      </ZAnchor>

      <ZAnchor :rotate="{z:-tau/40}" :translate="{x: 15}">
            <ZShape :path="[ { x:0, y: -50 },{ x:  0, y:  50 },{ x: 50, y:  50 },{ x: 0, y:  -50 }]" :fill="true" color="#FFF"  />
            <ZShape :path="[ { x:0, y: -50 },{ x:  0, y:  50 },{ x: -30, y:  50 },{ x: 0, y:  -50 }]" :rotate="{y:-tau/6}" :fill="true" color="#666"   />
      </ZAnchor>
    </ZAnchor>


  </ZIllustration>
</div>
</template>

<script>
import { ZIllustration, ZHemisphere, ZShape, ZAnchor} from 'vue-zdog'
import {TAU} from 'zdog'
// TAU = 360 deg
export default {
  data () {
    return {
      yrot: 0,
      py: 0,
      px: -6,
      pz: 5,
      tau: TAU,
      moving: false,
      isOn: false
    }
  },
  mounted(){

    //const timeline = new TimelineLite()
    this.movePlane(1);

  },
  methods: {
    reset: function(){
      this.moving = false;
    },
    movePlane: function(z){

      let n = 1;
      if(z==1){
        n = 5;
      }
      TweenLite.to(this,2,{ pz:z, delay:1, onComplete: this.movePlane, onCompleteParams:[n], ease:Sine.easeInOut});
    },
    setForm: function(){
      this.$emit('form',true);
    },
    setMenu: function(){
      if(!this.moving){
        this.moving = true;
        this.isOn = !this.isOn;
        this.$emit('menu',this.isOn);
      //  let state = "+";
      //  if(!this.isOn){state = "-"}
      //  TweenLite.set('.layout',{className: state+'=menu' });
        TweenLite.to(this, 1, {
          yrot: '+=' + (TAU / 2),
          ease: Sine.easeInOut,
          onComplete: this.reset
        })
      }

    }

  },
  components: {
    ZAnchor,
    ZShape,
    ZIllustration,
    ZHemisphere
  }
}
</script>
<style scoped>
</style>
