<template>

<div class="menu "  >

  <close-icon id="icon" v-if="showFormData" @clicked="closeForm"/>


  <div id="go" @click="goPage" v-if="!isForm"></div>
  <div id="goLeft" @click="spinMenu(1)" v-if="!isForm"></div>
  <div id="goRight" @click="spinMenu(-1)" v-if="!isForm"></div>
  <div id="dragger" v-if="!isForm" @mousedown="startDrag($event)" @mousemove="moveDrag($event)" @mouseup="stopDrag($event)" @touchstart="startDrag($event)" @touchmove="moveDrag($event)" @touchend="stopDrag($event)"></div>
  <!--
  <Screen img="http://www.spotdesign.co.uk/wp-content/uploads/2018/04/cb.jpg"/>
-->
<Plane id="plane" @form="setForm"/>

<form ref="form" id="formBg" class="gpu" v-if="isForm" action="">

  <input id="firstName" ref="firstName" name="firstName" type="text">
<transition name="fade">
  <input v-if="showFormData" id="from-email" ref="fromEmail" placeholder="Email" type="text" name="fromEmail">
</transition>
<transition name="fade">
  <textarea v-if="showFormData" ref="message" id="message" name="message" placeholder="Get in touch..."></textarea>
</transition>
</form>
<transition name="fade">
  <div id="send" v-if="showFormData" @click="sendForm">
    <img src="/assets/open-envelope2.png"/>
  </div>
</transition>

<ZIllustration id="logo" width="300" height="300" :zoom="1" >



<ZShape v-if="!isForm" :path="[ { x:   0, y: -10 },{ x:  -12, y:  0 },{ x: 0, y:  10},{ x: 0, y:  -10 }]" :translate="{x: -130 * arrscl, z: -50}"   :scale="{x:scl,y:scl}" :stroke="3*scl" :fill="true" :color="color" />
<ZShape v-if="!isForm" :path="[ { x:   0, y: -10 },{ x:  12, y:  0 },{ x: 0, y:  10},{ x: 0, y:  -10 }]" :translate="{x: 130 * arrscl, z: -50}"   :scale="{x:scl,y:scl}" :stroke="3*scl" :fill="true" :color="color" />

<ZAnchor :translate="{x: 0, y: 130}" :scale="{x:arrscl,y:arrscl}" v-if="!isForm">
  <ZShape :stroke="40*arrscl" color="#C25"  :path="[{ x: 0, y: 0, z:0 }]" />
  <ZShape :path="[ { x:   0, y: -5 },{ x:  10, y:  10 },{ x: -10, y:  10 },{ x: 0, y:  -5 }]"  :scale="{x:0.55,y:0.55}" :rotate="{z:tau/2}" :translate="{x: 0, y: 3, z:10}" :stroke="3" :fill="true" color="#FFF" />
</ZAnchor>

  <ZAnchor :rotate="{ y: rot}">

 <Eyeball :scl="scl*10" v-if="page == 'home' && !isForm" :animating="animating" :dragging="dragging"/>


      <ZHemisphere :diameter="bgscl*200" :fill="true" :rotate="{x:tau/2}" color="#C25" backface="#111"/>



    <ZAnchor :translate="{x: -32, z:40}" v-if="page == 'work' && !isForm">
      <ZShape :path="[ { x:   0, y: -10 },{ x:  10, y:  10 },{ x: -10, y:  10 },{ x: 0, y:  -10 }]" :scale="{x:scl,y:scl}" :rotate="{z:tau/2}" :stroke="3*scl" :fill="true" :color="color" />
      <ZShape :path="[ { x:   0, y: -10 },{ x:  10, y:  10 },{ x: -10, y:  10 },{ x: 0, y:  -10 }]" :scale="{x:scl,y:scl}" :translate="{x: 10*scl}" :rotate="{z:tau/2}" :stroke="3*scl" :fill="true" :color="color" />

      <ZShape :stroke="25 * scl" :color="color" :translate="{x: 31*scl}" :path="[{ x: 0, y: 0, z:0 }]" :scale="{x:scl,y:scl}"/>
      <ZShape :path="[ { x: -1, y: 4 },{ x:3, y: 4}]" :translate="{x: 55*scl}" :rotate="{z:tau/2}" :stroke="scl*15" :color="color" :scale="{x:scl,y:scl}" />
      <ZShape :path="[ { x: 0, y: -6 },{ x:  10, y:  6 },{ x: -10, y:  6 },{ x: 0, y:  -6 }]" :translate="{x: 52*scl}" :rotate="{z:tau/4}" :scale="{x:scl,y:scl}" :stroke="3*scl" :fill="true" :color="color" />
      <ZShape :path="[ { x: 0, y: -6 },{ x:  10, y:  8 },{ x: -10, y:  8 },{ x: 0, y:  -6 }]" :translate="{x: 59*scl, y:8 * scl}" :rotate="{z:-tau/3.5}" :scale="{x:scl/4,y:scl/4}" :stroke="3*scl" :fill="true" :color="color" />
      <ZShape :path="[ { x: 0, y: 0 },{ x:  0, y:  12 },{ x: 12, y:  0 },{ x: 0, y:  0 }]" :translate="{x: 67*scl, y:-10 * scl}"  :stroke="3*scl" :scale="{x:scl,y:scl}" :fill="true" :color="color" />
      <ZShape :path="[ { x: 0, y: 0 },{ x:  0, y:  -12 },{ x: 12, y:  0 },{ x: 0, y:  0 }]" :translate="{x: 67*scl, y:10 * scl}"  :stroke="3*scl" :scale="{x:scl,y:scl}" :fill="true" :color="color" />

      </ZAnchor>

      <ZAnchor :translate="{x: -44, z:40}" v-if="page == 'about' && !isForm">
       // A
        <ZShape :path="[ { x:0, y: -10 },{ x:  10, y:  10 },{ x: -10, y:  10 },{ x: 0, y:  -10 }]" :scale="{x:scl,y:scl}" :stroke="3*scl" :fill="true" :color="color" />
       // B
        <ZShape :path="[ { x:2, y: 7 },{ x:5, y: 7}]" :translate="{x: 25*scl}" :rotate="{z:tau/2}" :stroke="scl*12" :color="color" :scale="{x:scl,y:scl}" />
        <ZShape :path="[ { x: 1, y: -5 },{ x:5, y: -5}]" :translate="{x: 25*scl}" :rotate="{z:tau/2}" :stroke="scl*14" :color="color" :scale="{x:scl,y:scl}" />
        <ZShape :path="[ { x: -9, y: -8 },{ x:-9, y: 5}]" :translate="{x: 25*scl}" :stroke="scl*5" :color="color" :scale="{x:scl,y:scl}" />
       // O
        <ZShape :stroke="25 * scl" :color="color" :translate="{x: 45*scl, y: -1}" :path="[{ x: 0, y: 0, z:0 }]" :scale="{x:scl,y:scl}"/>
        // U
        <ZRoundedRect :cornerRadius="2*scl" :width="17" :translate="{x: 70*scl, y: -3.5*scl}" :scale="{x:scl,y:scl}" :height="15" :color="color" :fill="true" :stroke="scl*3"/>
        <ZShape :stroke="20 * scl" :color="color" :translate="{x: 70*scl, y: 1}" :path="[{ x: 0, y: 0, z:0 }]" :scale="{x:scl,y:scl}"/>

        // T
        <ZShape :path="[ { x:92, y: 9 },{ x:  92, y:  -11 },{ x: 101, y:  -11 },{ x: 92, y:  9 }]" :scale="{x:scl,y:scl}" :stroke="3*scl" :fill="true" :color="color" />
        <ZShape :path="[ { x:88, y: -8 },{ x:  88, y:  -11 },{ x: 83, y:  -11 },{ x: 88, y:  -8 }]" :scale="{x:scl,y:scl}" :stroke="3*scl" :fill="true" :color="color" />

        </table>

      </ZAnchor>


  </ZAnchor>
</ZIllustration>


</div>
</template>

<script>
import { ZIllustration, ZShape, ZAnchor, ZHemisphere, ZRoundedRect} from 'vue-zdog'
import {TAU} from 'zdog'
import Eyeball from '@/components/elements/Eyeball'
import Plane from '@/components/elements/Plane'
import axios from 'axios'
import CloseIcon from '@/components/elements/CloseIcon'
// TAU = 360 deg
export default {
  data () {
    return {
      scl: 0,
      bgscl: 0,
      dist: 0,
      isForm: false,
      showFormData: false,
      color: '#FFF',
      arrscl: 0,
      tau: TAU,
      dir: -1,
      rot: 0,
      startPos: 0,
      animating: false,
      dragging: false
    }
  },
  props: ['page','csrf'],
  components: {
    ZAnchor,
    Plane,
    ZIllustration,
    ZRoundedRect,
    ZShape,
    ZHemisphere,
    Eyeball,
    CloseIcon
  },
  mounted(){
    TweenLite.to(this,1,{arrscl: 1, delay:1, ease: Sine.easeInOut});

    //TweenLite.to(this,1,{line: 3, delay:0.5});
    TweenLite.to(this,1,{bgscl: 1, delay:0.5});
    TweenLite.to(this,1,{scl: 1, delay:0.5});

  },
  methods: {
    spinMenu: function(dir){
      this.dragging = false;
      this.animating = true;
      this.rot = ((5*dir) / TAU) / -20;
      TweenLite.to(this,0.4,{scl: 0, arrscl: 0, color:'#888', ease: Sine.easeIn, onComplete: this.swapPage});
      TweenLite.to(this,1,{rot: (TAU * dir), ease: Sine.easeOut, onComplete: this.reset});

    },
    setForm: function(){
      if(!this.isForm){
        this.isForm = true;
        setTimeout(this.openForm,100)
      }
    },
    openForm(){
        TweenLite.set('#formBg',{className: '+=active' });
      //  TweenLite.set('#formBg',{borderRadius: 0, width: '380px', height: '350px', background: '#FFF'});
        setTimeout(this.showForm,400)
    },
    killForm(){
      this.isForm = false;
    },
    hideForm(){
        TweenLite.set('#formBg',{className: '-=active' });
    },
    showForm(){
      this.showFormData = true;
    },
    closeForm(){
      this.showFormData = false;
      setTimeout(this.hideForm,500);
    //  TweenLite.set('#formBg',{borderRadius: '50%', width: '162px', height: '162px', background: '#000', delay:1});
      setTimeout(this.killForm,1000);
      console.log('CLOSE');
    },
    sendForm(){

    let formData = new FormData(this.$refs.form);
    formData.append('action', 'contact-form/send');
    formData.append(this.csrf.csrfTokenName, this.csrf.csrfTokenValue);

    let t = this;
    axios.post('/', formData, {
      headers: {
        'X-CSRF-Token': this.csrf.csrfTokenValue,
        'Content-Type': 'application/json'
      }})
          .then(function (response) {
          //  console.log(response);
            t.closeForm();
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    getEvent: function(e){

      if(e.type.indexOf('mouse')>-1 || e.type.indexOf('pointer')>-1){
        return e;
      } else {
        let touches = e.targetTouches;
        if(touches.length == 1){
          return touches[0];
        }
      }
      return null;
    },
    startDrag: function(e){
      if(!this.animating){
        this.dist = 0;
        TweenLite.killTweensOf(this);
        let ev = this.getEvent(e);
        if(ev){
          this.startPos = ev.clientX;
          TweenLite.to(this,0.2,{arrscl: 0, ease: Sine.easeOut});
          this.dragging = true;
        }
      }
    },
    swapPage: function(){

      TweenLite.to(this,0.5,{scl: 1, color:'#FFF', ease: Sine.easeOut});
      TweenLite.to(this,1,{arrscl: 1, ease: Sine.easeInOut});
      this.$emit('newPage',this.dir);
    },
    goPage(){
      this.$emit('goPage');

    },
    reset(){
      this.animating = false;
    //  this.stopDrag();
    },
    stopDrag: function(e,isNew = false){

    if(this.dist < 10){
      this.goPage();
      return;
    }

        if(this.dragging && !this.animating && !this.isForm){
          this.dragging = false;
          this.animating = true;

          if(!isNew){

            TweenLite.to(this,0.6,{rot: 0, scl: 1, ease: Sine.easeInOut});
            TweenLite.to(this,1,{arrscl: 1, ease: Sine.easeInOut, onComplete: this.reset});
          } else {
            TweenLite.to(this,0.4,{scl: 0, arrscl: 0, color:'#888', ease: Sine.easeIn, onComplete: this.swapPage});
            TweenLite.to(this,1,{rot: (TAU * this.dir), ease: Sine.easeOut, onComplete: this.reset});
          }

      }
    },

    moveDrag: function(e){
      if(this.dragging){
          let ev = this.getEvent(e);
        if(ev){
          this.rot = ((ev.clientX - this.startPos) / TAU) / -20;
          let del = 1 - (Math.abs(ev.clientX - this.startPos)/250);
          if(del < 0){del = 0}
          this.scl = del;

          if(this.rot*TAU<0){
            this.dir = -1
          } else {
            this.dir = 1
          }
          this.dist = Math.abs(ev.clientX - this.startPos);
          if(Math.abs(this.rot*TAU) > 2){
              this.stopDrag(null,true)
              //console.log('long');
            }
        //this.line = this.line * this.scl;

      }
    }
  }

  }
}
</script>
<style scoped lang="scss">
#logo{pointer-events: none;}
#icon{position: absolute; top: 50%; left: 50%; transform: scale(0.55,0.55) translate(-50%, -450px); z-index: 80}
#send{width:60px; height:60px; border-radius: 30px; background: #C25; position: absolute; left:50%; top:50%; transform: translate(-50%,210px); text-align: center; line-height: 60px; color: #FFF}
#send img{width:35px; height: auto; margin: 10px 0}
textarea, #from-email {
    background: #DDD;
    border:none;
    overflow: auto;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    padding: 5%;
    width:90%;
    height:68%;
    font-size: 18px;
    font-family: $font;
}
#from-email{width:90%; height:5%; padding:5%; margin-bottom: 4%}
#formBg{position: absolute;  z-index: 60; width:162px; height: 162px; padding:20px; background: #000; border-radius: 50%; top:50%; left:50%; transform: translate(-50%,-50%); transition: all 0.5s}
#formBg.active{border-radius: 0; width: 80%; height: 350px; background: #FFF;
  @include sm {
    width: 380px;
  }
}

#plane{position: absolute; top:-12px; right:-10px; left: auto;
  @include lg {
    top:0;
    right:0;
  }
}
input#firstName { display: none; }
#go,#dragger,#goLeft,#goRight{position: absolute; top:50%; left:50%; margin-top: 130px; width: 60px; height: 60px; transform: translate(-50%,-50%);}
#dragger{width: 200px; height: 200px; margin:0;}
#goLeft,#goRight{width: 70px; height: 200px;}
#goLeft{margin:0 0 0 -135px}
#goRight{margin:0 0 0 135px}
.menu{background-color: rgb(136,136,136); position: fixed; z-index: 100; top:0; right:0; bottom:0; left:0}
</style>
