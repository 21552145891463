<template>
  <div class="gpu" @click="setMenu">
  <ZIllustration :width="150" :height="150" :zoom="0.5">


    <ZAnchor :rotate="{y: yrot}" :translate="{x: 0, z:0}">
      <ZHemisphere :diameter="150" :fill="true" color="#C25" backface="#111"/>
      <ZShape :path="[{ x: -25, y: 20, z:70 },{ x: 25, y: 20, z:70 }]" :stroke="12" color="#FFF" />
      <ZShape :path="[{ x: -25, y: 0, z:70 },{ x: 25, y: 0, z:70 }]" :stroke="12" color="#FFF" />
      <ZShape :path="[{ x: -25, y: -20, z:70 },{ x: 25, y: -20, z:70 }]" :stroke="12" color="#FFF" />

      <ZShape :path="[{ x: -25, y: 0, z:-50 },{ x: 25, y: 0, z:-50 }]" :rotate="{z:tau/8}" :stroke="12" color="#FFF" />
      <ZShape :path="[{ x: -25, y: 0, z:-50 },{ x: 25, y: 0, z:-50 }]" :rotate="{z:-tau/8}" :stroke="12" color="#FFF" />
    </ZAnchor>
  </ZIllustration>
</div>
</template>

<script>
import { ZIllustration, ZHemisphere, ZShape, ZAnchor} from 'vue-zdog'
import {TAU} from 'zdog'
// TAU = 360 deg
export default {
  data () {
    return {
      yrot: 0,
      tau: TAU,
      moving: false,
      isOn: false
    }
  },
  mounted(){

    //const timeline = new TimelineLite()


  },
  methods: {
    reset: function(){
      this.moving = false;
    },
    setMenu: function(){
      if(!this.moving){
        this.moving = true;
        this.isOn = !this.isOn;
        this.$emit('menu',this.isOn);
      //  let state = "+";
      //  if(!this.isOn){state = "-"}
      //  TweenLite.set('.layout',{className: state+'=menu' });
        TweenLite.to(this, 1, {
          yrot: '+=' + (TAU / 2),
          ease: Sine.easeInOut,
          onComplete: this.reset
        })
      }

    }

  },
  components: {
    ZAnchor,
    ZShape,
    ZIllustration,
    ZHemisphere
  }
}
</script>
<style scoped>
</style>
