<template>
  <div class="layout" :class="{noscroll: menuOn}">

    <transition v-on:enter="enter" v-on:leave="leave">
      <router-view v-if="!menuOn" @dologo="setLogo"/>
    </transition>


    <transition name="fade">
      <Menu id="menu" :csrf="csrf" :page="pages[pagePos]" v-if="menuOn" @newPage="page" @goPage="newPage"/>
    </transition>
    <Logo v-if="logoOn" ref="log" id="logo" class="shadow logo" :class="this.$route.name" :lineCol="col" :dotCol="dot" :dot="dotSize" :stroke="strokeSize" :scl="logoScale"/>
    <Burger ref="burger" id="burger"  @menu="setMenu" />
  </div>
</template>

<script>
import Logo from '@/components/elements/Logo'
import Burger from '@/components/elements/Hamburger'
import Menu from '@/components/elements/Menu'
export default {
  data () {
    return {
      menuOn: false,
      pages: ['home','work','about'],
      pagePos: 0,
      col: '#FFF',
      dot: '#C25',
      strokeSize: 22.5,
      dotSize: 35,
      logoOn: true,
      logoScale: {x:1, y:1}
    }
  },
  props:['csrf'],
  mounted(){
    console.log('mmm');
    if(this.$route.path != '/' && this.$route.path != '/about'){
      this.fadeLogo(2);
    } else {
      if(this.$route.name == 'about'){

        this.strokeSize = 0;
        this.dotSize = 0;

      //  this.setMenu();
      }
    }

  },
  components: {
    Logo,
    Burger,
    Menu
  },
  methods:{
    fadeLogo(del){
      TweenLite.to(this,1,{dot:"#000", delay:del});
      TweenLite.to(this,1,{strokeSize: 0, delay:del});
      TweenLite.to(this,1,{dotSize: 0, delay:del, onComplete: this.killLogo});
    },
    setLogo(){
      this.strokeSize = 0;
      this.dotSize = 0;
      this.setMenu();
    },
    killLogo(){
      this.logoOn = false;
    },
    enter: function(el,done){
      TweenLite.to(el,0.6,{opacity:1, onComplete:done});
    },
    leave: function(el,done){
      TweenLite.to(el,0.3,{opacity:0, onComplete:done});
    },
    newPage(){
    //  if(this.pages[this.pagePos] == 'work'){
  //  if(this.pages[this.pagePos] == 'about'){
  //      this.setLogo();
  //  }
    this.$router.push({name: this.pages[this.pagePos]});
    this.$refs.burger.setMenu();
    //  }
    //  if(this.pages[this.pagePos] == 'home'){
    //    this.$router.push({path: '/'});
    //    this.$refs.burger.setMenu();
    //      TweenLite.set('#wall',{display:'none' });
    //  }
    },
    page: function(dir){
      let p = this.pagePos + dir;
      if(p > 2){
        p = 0;
      }
      if(p<0){
        p = 2;
      }
    //console.log(p);
      this.pagePos = p;

    },
    showLogo(s,d,del = 0){
      TweenLite.to(this,1,{strokeSize: s, delay: del});
      TweenLite.to(this,1,{dotSize: d, delay: del});

    },

    setMenu: function(state = false){
    //  console.log('MENU');
      this.menuOn = state;
      //this.$refs.log.twang();
      if(state){
          TweenLite.set('body',{className: '+=noscroll' });
          //TweenLite.to(this,1,{col:"#FFF"});
          this.fadeLogo(0);
      //    TweenLite.set('#logo',{className: '+=parkLogo' });
        //this.col = '#FFF'
      } else {

        TweenLite.set('body',{className: '-=noscroll' });
        if(this.$route.name == 'home' || this.$route.name == 'about'){
          this.logoOn = true;
          if(this.$route.name == 'home'){
            this.showLogo(22.5,35);
          } else {
            this.showLogo(11,17,0.5);
          }
          TweenLite.to(this,1,{dot:"#C25"});
        }
      }
    }
  }
}
</script>

<style lang="scss">
body {

  margin:0;
  background: #111;
  padding:0;
  overflow-x: hidden;
}
h1{
  font-size: 20px; color: #EEE;
  padding: 30px 0;
  margin-top: 100px;
  @include sm {
    font-size: 38px;
    margin-top: 0;
  }
}
.col{margin: 0 auto; color: #FFF; max-width: 1200px; padding:0 15px}
.num{position: absolute; top:20px; left:20px; color:#FFF}
.slide{
  overflow: hidden;
  position: fixed;
  text-align: center; padding:0;
  top:0; left:0; right:0; bottom:0;
}

.hide{display:none;}

.shadow{  -webkit-filter: drop-shadow( 0 0 8px rgba(0, 0, 0, .75)); filter: drop-shadow( 0 0 8px rgba(0, 0, 0, .75));}
#logo{position: fixed; top:50%; left: 50%; transform: translate(-50%,-50%); z-index: 50; pointer-events: none; transform: scale 0.5s;
  @include sm {
    transform: translate(-50%,-50%) scale(1,1);
  }

}
#logo.about{top:15%; position:absolute; transform: translate(-50%,-50%) scale(0.7,0.7)}

#logo.home{
transform: translate(-50%,-50%) scale(0.7,0.7);
  @include sm {
    transform: translate(-50%,-50%) scale(1,1);
  }

}

#burger{
  position: fixed;
  z-index: 150;
  top:-20px;
  left:-20px;
  @include lg {
    top:0;
    left:0;
  }

}

.gpu {
   -webkit-transform: translate3d(0, 0, 0);
   -moz-transform: translate3d(0, 0, 0);
   -ms-transform: translate3d(0, 0, 0);
   transform: translate3d(0, 0, 0);
  /* Other transform properties here */
}

.layout {
  position: absolute;
  top:0; left:0; right:0;
  background-color: #111;
  transition: background-color 1s;

}
.noscroll{overflow: hidden !important;}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-active{

}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}




</style>
