<template>
  <div id="icon" class="Icon" @mouseenter="toggle" @mouseleave="toggle" @click="clicked">
    <span></span>
    <span></span>
    <span></span>
  </div>
</template>

<script>

// TAU = 360 deg
export default {

  data () {
    return {

    }
  },
  props: ['anim'],
  mounted(){

  },
  methods: {
    clicked(){
      this.$emit('clicked')
    },
    toggle(e){
      if(this.anim){
        e.currentTarget.classList.toggle('open');
      }
    //  document.getElementsByTagName('body')[0].classList.toggle('open');
    }

  }
}
</script>
<style scoped lang="scss">
* {
  margin: 0;
  padding: 0;
}

.Icon {
  width: 50px;
  height: 50px;
  position: relative;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
}

.Icon span {
  display: block;
  position: absolute;
  left: 0;
  top: 23px;
  height: 8px;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  transform: rotate(0deg);
  transition: .2s ease-in-out;
}

.Icon span:nth-child(1),
.Icon span:nth-child(3) {
  width: 50%;
  transform: rotate(45deg);
}

.Icon span:nth-child(1) {
  left: 3px;
  top: 15px;
}

.Icon span:nth-child(2) {
  transform: rotate(-45deg);
}

.Icon span:nth-child(3) {
  left: 20px;
  top: 31px;
}

.Icon.open span:nth-child(1),
.Icon.open span:nth-child(3) {
    left: -1px;
}

.Icon.open span:nth-child(1) {
  top: 15px;
  transform: rotate(135deg);
}

.Icon.open span:nth-child(2) {
  transform: rotate(0deg);
}

.Icon.open span:nth-child(3) {
  top: 31px;
  transform: rotate(45deg);
}
</style>
