import Vue from "vue";
import Components from "./components";
import {TweenLite} from "gsap";
import router from './router'
import vueHeadful from 'vue-headful'
import {VueMasonryPlugin} from 'vue-masonry';
import VueAnalytics from 'vue-analytics'

Vue.config.productionTip = false;

Vue.component('vue-headful', vueHeadful);
Vue.use(VueMasonryPlugin)

Vue.use(VueAnalytics, {
  id: 'UA-52856111-2',
  router
})

new Vue({
  el: "#app",
  router,
  delimiters: ["${", "}"]
});
