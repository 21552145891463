import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)



const router = new Router({
  mode: 'history',
  routes: [
    { path: '/', name: "home", component: require("@/components/pages/Index").default },
    { path: '/work', name: "work", component: require("@/components/pages/Work").default },
    { path: '/about', name: "about", component: require("@/components/pages/About").default },
    { path: '/work/:slug', name: "job", component: require("@/components/pages/WorkEntry").default }

  ],
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
});


export default router
