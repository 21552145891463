<template>
  <div class="eye">
<ZShape v-if="!dragging && !animating" :path="[{ x: -6* scl, y: -6 * scl },{ x: 6* scl, y: -6 * scl },{ x: 6* scl, y: lidBottom * scl },{ x: -6* scl, y: lidBottom * scl },{ x: -6* scl, y: -6 * scl }]" :fill="true" :translate="{z: 12}" :stroke="0" color="#111" />
<ZAnchor :rotate="{x:xRot, y:yRot}">
      <ZShape :path="[{ x: 0, y: 0, z:0 }]" :stroke="12*scl" color="#FFF" />



      <ZShape :stroke="6 * scl" :color="this.eyeColor" :translate="{x: 0, y: 0, z: 11.9}" :path="[{ x: 0, y: 0, z:0 }]" />
      <ZShape :stroke="dia * scl" color="#000" :translate="{x: 0, y: 0, z: 12}" :path="[{ x: 0, y: 0, z:0 }]" />
     <!-- <ZShape :stroke="1 * scl" color="#FFF" :translate="{x: 10, y: -15, z: 12.2}" :path="[{ x: 0, y: 0, z:0 }]" /> -->
</ZAnchor>

</div>
</template>

<script>
import { ZIllustration, ZEllipse, ZShape, ZAnchor} from 'vue-zdog'
import {TAU} from 'zdog'
// TAU = 360 deg
export default {
  data () {
    return {
      tau: TAU,
      dia: 3,
      eyeColors: ["#a29bfe", "#74b9ff", "#ff7675", "#fab1a0", "#81ecec", "#55efc4"],
      isSpinning: true,
      eyeBallSize: 12*this.scl,
      eyeX: 500,
      eyeY: 500,
      cursorX: 0,
      cursorY: 0,
      maxY: 1.5,
      maxX: 1.5,
      minX: -1.5,
      minY: -1.5,
      lidBottom: -6,
      xRot:0,
      yRot:0,
      eyeColor: '#a29bfe'
    }
  },
  mounted(){
     this.eyeColor = this.eyeColors[Math.floor(Math.random() * this.eyeColors.length)];
     setTimeout(this.blink, 3000 + (Math.random() * 3000));
     setTimeout(this.move, Math.random() * 1000);
     let t = this;
     window.addEventListener('mousemove', e => {
       t.cursorX = e.clientX;
       t.cursorY = e.clientY;
      });

  },
  props: ['scl','index','dragging','animating'],
  methods: {
    move: function(){
      this.follow();
      setTimeout(this.move, Math.random() * 1000);
    },
    blink(){
      //console.log('blink');
      TweenLite.to(this,0.1,{lidBottom:6, onComplete: this.open});
    },
    open(){
      TweenLite.to(this,0.1,{lidBottom:-6});
      setTimeout(this.blink, 3000 + (Math.random() * 3000));
    },
    follow: function(){

    this.eyeX = document.documentElement.clientWidth/2;
    this.eyeY = document.documentElement.clientHeight/2;

    let maxDistance = 400;

    let differenceX = this.cursorX - (this.eyeX + this.eyeBallSize/2);
    if(differenceX > maxDistance){
      differenceX = maxDistance;
    }
    if(differenceX < -maxDistance){
      differenceX = -maxDistance;
    }
    let ratioY = 1 - (differenceX + maxDistance)/(maxDistance*2);
    //  this.yRot = ratioY * (this.maxY - this.minY) + this.minY;

    let differenceY = this.cursorY - (this.eyeY + this.eyeBallSize/2);
    if(differenceY > maxDistance){
      differenceY = maxDistance;
    }
    if(differenceY < -maxDistance){
      differenceY = -maxDistance;
    }
    let ratioX = 1 - (differenceY + maxDistance)/(maxDistance*2);
    //this.xRot = ratioX * (this.maxX - this.minX) + this.minX;




    let distance = Math.pow(Math.pow(differenceX, 2) + Math.pow(differenceY, 2), .5);
    let pupilMin = 3;
    let pupilMax = 5;

    if(distance > maxDistance){
      distance = maxDistance;
    }
    if(distance < -maxDistance){
      distance = -maxDistance;
    }
      let ratioEye = 1-(distance + maxDistance)/(maxDistance*2);


        TweenLite.to(this,0.5,{xRot: ratioX * (this.maxX - this.minX) + this.minX, yRot: ratioY * (this.maxY - this.minY) + this.minY, dia: ratioEye * (pupilMax - pupilMin) + pupilMin, ease: Sine.easeInOut})

    }
  },
  components: {
    ZAnchor,
    ZShape,
    ZIllustration,
    ZEllipse
  }
}
</script>
<style scoped>

</style>
