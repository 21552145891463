<template>

    <div id="wall" class="gpu" v-masonry column-width=".item-sizer" transition-duration="0s" gutter="0" item-selector=".item">
      <vue-headful :title="'SpotDesign | Work'"/>
      <div class="item-sizer"></div>

      <div v-masonry-tile class="item" v-for="(item, index) in posts" :key="'obj'+index">
        <div class="img-wrapper" :style="{paddingTop: ((item.media[0].height / item.media[0].width) * 100) + '%'}">
         <router-link :to="item.uri"><img :src="item.media[0].low" @load="loaded($event)" @click="goTo(item.uri)"/></router-link>
         <router-link :to="item.uri" v-if="lods.length > index"><img :src="item.media[0].file" @click="goTo(item.uri)"/></router-link>
        </div>
      </div>

    </div>

</template>


<script>
import server from '@/server'
export default {
  data () {
    return {
      lodNum: -1,
      lods:[],
      posts:[]
    }
  },
  methods:{
    goTo: function(uri){
      TweenLite.set('#wall',{opacity:0});
      this.$router.push({path: uri});
    },
    catch: function(data){
      this.posts = data.data;
      this.lodNum = 0;

      //console.log(data);

    },
    clear(){
      this.lodNum = -1;
    },
    loaded(e){
      let img = e.currentTarget;
      this.lods.push(true);
      console.log(this.lods.length);
      TweenLite.set('#wall',{opacity:1});
    //  TweenLite.to(img,0.5,{opacity:1, delay:0.2});
    }
  },
  mounted(){
  //  TweenLite.set('.item',{opacity:0});
   TweenLite.set('#wall',{opacity:1});
    server.fetch('work',this.catch)
  }
}
</script>
<style scoped lang="scss">
#wall{padding:0; margin: 10px; position: absolute; top: 0; left:0; right:0; opacity: 1}
.loaded{opacity: 1}
.item, .item-sizer{
  width: 50%;
  @include sm {
    width: 33%;
  }
  @include lg {
    width: 20%;
  }
}
.img-wrapper{position: relative}

.item img{width:100%; height: auto; position: absolute; top: 0; left:0;}
.item .hid{ opacity: 0}
</style>
